import { Box, Table } from '@mantine/core';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingBar, { type LoadingBarRef } from 'react-top-loading-bar';
import { EmptyList } from '../EmptyList';
import { MemberNotFound } from '~/assets/images';

export type RenderFunction<
  T extends Record<string, unknown>,
  K = React.ReactNode,
> = (rowData: T, index: number) => K;

export interface TableColumn<T extends Record<string, unknown>> {
  // Add the ability to add actions column
  id: string;
  label: string;
  isIndex?: boolean;
  render?: RenderFunction<T>;
  hidden?: boolean;
  priority?: boolean;
  sortable?: boolean;
  width?: number | string;
}

export interface TableProps<T extends Record<string, unknown>> {
  columns: TableColumn<T>[];
  data?: T[];
  tableName: string;
  isLoading?: boolean;
  isSelectable?: boolean;
  isRowClickable?: boolean;
  emptyResultTitle?: string;
  emptyResultDescription?: string;
  notFoundImage?: string;
  actions?: {
    label: React.ReactNode;
    /**
     * @param data - Table data
     * @param selectedIndexes - Indexes of selected rows, that are going to be affected by the action
     *
     * @returns Promise<any> - Make sure you return a promise here even if your function is sync.
     * e.g. `return Promise.resolve()` at the end of your sync function
     */
    // onClick: (params: ActionParams<T>) => Promise<any>;
  }[];
  onRowClick?: (params: any) => void;
  renderCustomExtendedRow?: RenderFunction<T>;
}

function TableComponent<T extends Record<string, unknown>>({
  data = [],
  columns,
  tableName,
  isLoading,
  renderCustomExtendedRow,
  emptyResultTitle,
  emptyResultDescription,
  onRowClick,
  isRowClickable,
  notFoundImage = MemberNotFound,
}: TableProps<T>) {
  const { t } = useTranslation();
  const loadingBarRef = useRef<LoadingBarRef>(null);

  const startLoading = useCallback(
    () => loadingBarRef.current && loadingBarRef.current.continuousStart(20),
    []
  );

  const completeLoading = useCallback(
    () => loadingBarRef.current && loadingBarRef.current.complete(),
    []
  );

  useEffect(() => {
    if (loadingBarRef.current) {
      if (isLoading) startLoading();
      else completeLoading();
    }
  }, [completeLoading, data, isLoading, startLoading]);

  const TableRowComponent = ({ element, i }: { element: T; i: number }) => {
    return (
      <>
        <Table.Tr
          key={`${tableName}_row_${i}`}
          onClick={function () {
            if (onRowClick) {
              onRowClick(element);
            }
          }}
          style={{
            cursor: isRowClickable ? 'pointer' : '',
          }}
        >
          {columns.map((column) => {
            let value = element[column.id] as React.ReactNode;
            if (column.render !== undefined) value = column.render(element, i);
            return (
              <Table.Td
                key={`${tableName}_row_${i}_cell_${column.id}`}
                id={`${tableName}_row_${i}_cell_${column.id}`}
                w={column.width}
              >
                {value ?? ''}
              </Table.Td>
            );
          })}
        </Table.Tr>

        {renderCustomExtendedRow?.(element, i) !== undefined && (
          <Table.Tr key={`${tableName}_row_${i}_extended`} w={'100%'}>
            <Table.Td
              key={`${tableName}_row_${i}_cell_extended`}
              id={`${tableName}_row_${i}_cell_extended`}
              colSpan={99}
            >
              {renderCustomExtendedRow(element, i)}
            </Table.Td>
          </Table.Tr>
        )}
      </>
    );
  };

  return (
    <Box pos="relative">
      <LoadingBar
        ref={loadingBarRef}
        color="var(--primary-main)"
        containerStyle={{
          position: 'absolute',
          zIndex: 0,
          width: '100%',
          overflow: 'hidden',
        }}
      />

      {data.length === 0 && !isLoading ? (
        <EmptyList
          title={emptyResultTitle ?? t('event.label.no_attendee')}
          subtitle={
            emptyResultDescription ?? t('event.label.no_attendee_description')
          }
          image={notFoundImage}
        />
      ) : (
        <Table verticalSpacing="md" highlightOnHover>
          <Table.Thead>
            <Table.Tr>
              {columns.map((col, colIndex) => (
                <Table.Th key={col.label + colIndex}>{col.label}</Table.Th>
              ))}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {data.map((element, i) => (
              <TableRowComponent
                element={element}
                i={i}
                key={`${tableName}_row_${i}`}
              />
            ))}
          </Table.Tbody>
        </Table>
      )}
    </Box>
  );
}

export default TableComponent;
